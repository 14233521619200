import React from "react"
import {Grid, SvgIcon} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import logo from "../themes/mential-new-logo-white.svg"
import logoDark from "../themes/mential-new-logo-blue.png"
import whatsappIcon from "../views/img/social/whatsappIconWhite.png"
import whatsappIconBlack from "../views/img/social/whatsappIconBlack.png"
import Grid2 from "@mui/material/Unstable_Grid2";
import cross from "../views/img/cross.png";

export default function Nav(props) {


    return (<Grid container bgcolor={props.bgcolor ? props.bgcolor : "third.main"} sx={{
        padding: {xs: '16.5px 0px', md: '16.5px'}, position: props.floating ? "fixed" : "relative", zIndex: 1000, top:0, background: props.bgcolor ? props.bgcolor : "third.main"
    }}>
        {
            props.closeAction !== undefined ?
                <Grid item xs={1}> <img style={{
                    padding: "5px 15px",
                    cursor: "pointer",
                    filter: props.bgcolor === "white" ? null : "invert(100%)"
                }} alt={"exit"} src={cross} onClick={props.closeAction}/></Grid> : null
        }
        <Grid item xs={0} sm={props.contactWhatsapp ? 3 : 0}></Grid>
        <Grid item xs={props.contactWhatsapp ? 6 : props.closeAction !== undefined ? 10 : 12}
              sm={props.contactWhatsapp ? 6 : props.closeAction !== undefined ? 10 : 12} display="flex" justifyContent="center" alignItems="center">
            <img src={props.bgcolor && props.bgcolor === "white" ? logoDark : logo} width="140px" alt="logo"/>
        </Grid>
        {
            props.closeAction !== undefined ?
                <Grid item xs={1} ><span style={{
                    padding: "5px 15px"
                }} /></Grid> : null
        }
        {
            props.contactWhatsapp ?
                <Grid2 container xs={6} sm={props.contactWhatsapp ? 3 : 0} display={"flex"} justifyContent={"right"}
                       alignItems={"center"}>
                    <Grid2 item key={"callUs"} xs={8} display={"flex"} justifyContent={"right"}>
                    <span style={{
                        color: props.bgcolor && props.bgcolor === "white" ? "black" : "white",
                        cursor: "Pointer"
                    }} onClick={() => {
                        window.open('tel:669807232');
                    }}
                          className={"TextTinyNormal"}>¿Tienes dudas? <br/>669 807 232</span>
                    </Grid2>
                    <Grid2 item key={"whatsapap"} xs={4} display={"flex"} justifyContent={"center"}>
                        <img src={props.bgcolor && props.bgcolor === "white" ? whatsappIconBlack : whatsappIcon}
                             width="25px" alt={"whatsapp icon"} style={{cursor: "Pointer"}} onClick={() => {
                            window.track("clickWhatsappContactIcon", {page: window.location.pathname})
                            window.open("https://api.whatsapp.com/send?phone=34669807232&text=Hola!%20Quiero%20saber%20mas%20sobre%20sus%20servicios%20de%20psicologia%20online", "_blank")
                        }}/></Grid2> </Grid2> : null
        }
        {props.customRightComponent}
    </Grid>)

}
