import Nav from "../../components/Nav";
import loadingGif from "../../themes/LoadingGifAlt.gif";
import {Container} from "@mui/material";
import React from "react";
import iconLogo from "../../themes/logo.png";

export default function LoadingView({displayText, nav}) {

    console.log("LoadingView")
    //Apply bouncy animation to loading gif
    return (<Container>
        {nav ? <Nav/> : null}
        {
            displayText === undefined || displayText === false ?
                <div className="centered bounce-4 box-animated"><ul style={{listStyle:"none",padding:0}}><li style={{textAlign:"center"}}><img width="40px" height="40px" src={iconLogo}/></li></ul></div> :
            <div className={"centered"}><ul style={{listStyle:"none",padding:0}}><li style={{textAlign:"center"}}><img width="40px" height="40px" src={iconLogo}/></li>
                <li>  <div className="codinfox-changing-keywords">
                    {
                        displayText.map((text) => {
                            return <span className="hidden TextRegularSemibold" style={{textAlign:"center"}}>{text}</span>
                        })
                    }
                </div></li>
            </ul>
            </div>
            
        }


    </Container>)
}
