export default class ProductAnalytics {

    trackEvent(eventName, properties) {
        if (window.mixpanel) window.mixpanel.track(eventName, properties);
        if (window.zaraz) window.zaraz.track(eventName, {ga: true, ...properties});
        if (window.posthog) window.posthog.capture(eventName, properties);
        if (window._cio) window._cio.track(eventName, {});
        if (window.smartlook) window.smartlook('track', eventName, properties);
    }

    identify(email, userId) {
        if (window.posthog) window.posthog.identify(userId);
        if (window._cio) window._cio.identify({id: userId, email: email});
        if (window.clarity) window.clarity("identify", userId);
        if (window.smartlook) window.smartlook('identify', userId);
    }

    track(eventName, properties) {
        //console.log(eventName)
        if (window.posthog) {
            if (window.postHogInterval) clearInterval(window.postHogInterval)
            this.iteratePendingEvents();
            window.posthog.capture(eventName, properties);

        } else {

            if (window.postHogEvent) {
                window.postHogEvent.push({name: eventName, properties: properties})
            } else {
                window.postHogEvent = [{name: eventName, properties: properties}]
            }
            if (!window.postHogInterval) {
                window.postHogInterval = setInterval(() => {
                    this.iteratePendingEvents();
                }, 3000);
            }


        }
        try {
            if (window.mixpanel) window.mixpanel.track(eventName, properties);
            if (window.zaraz) window.zaraz.track(eventName, {ga: true, ...properties});

            if (window._cio) window._cio.track(eventName, {});
        } catch (e) {
            console.error(e)
        }


    }

    iteratePendingEvents() {
        if (window.posthog && window.postHogEvent && window.postHogEvent.length > 0) {
            const length = window.postHogEvent.length;
            for (let i = 1; i <= length; i++) {
                let event = window.postHogEvent.shift();
                window.posthog.capture(event.name, event.properties);
            }
        }
    }
}
