import React, {lazy, Suspense, useEffect, useState} from "react"
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {appTheme} from "./themes/theme";
import {Button, ThemeProvider} from "@mui/material";
import TagManager from "react-gtm-module";
import lazyRetry from "./services/lazyRetry";
import LoadingView from "./views/loading/LoadingView";
import ProductAnalytics from "./services/ProductAnalyticsTracker";
import {ErrorBoundary} from "@sentry/react";
import {usePostHog} from 'posthog-js/react'
import  "./services/firebase";

const IntroAssessment = lazy(() => lazyRetry(() => import('./views/assessment/IntroAssessment')));
const NotFoundPage = lazy(() => lazyRetry(() => import('./views/notFound/NotFoundPage')));
const ThankyouAssessment = lazy(() => lazyRetry(() => import('./views/assessment/ThankyouAssessment')));
const Account = lazy(() => lazyRetry(() => import('./views/account/AccountView')));
const Auth = lazy(() => lazyRetry(() => import('./views/auth/LoginView')));
const LogoutView = lazy(() => lazyRetry(() => import('./views/auth/LogoutView')));
const AssessmentReportRecovery = lazy(() => lazyRetry(() => import('./views/assessment/AssessmentReportRecovery')));
const ProgramOnboarding = lazy(() => lazyRetry(() => import('./views/assessment/ProgramOnboarding')));
const VideoConference = lazy(() => lazyRetry(() => import('./views/videoconference/VideoconferenceView')));
const SignUpView = lazy(() => lazyRetry(() => import('./views/auth/SignupView')));
const AdminView = lazy(() => lazyRetry(() => import('./views/admin/AdminView')));
const IntroView = lazy(() => lazyRetry(() => import('./views/account/IntroView')));
const ForgetPasswordView = lazy(() => lazyRetry(() => import('./views/auth/ForgetPasswordView')));
const PartnerAccountView = lazy(() => lazyRetry(() => import('./views/partner/PartnerAccountView')));

export default function App(props) {


    const postHog = usePostHog()
    const { appUpdatePending, updateAction } = props;
    const [showUpdateBtn, setShowUpdateBtn] = useState(appUpdatePending);
    function gtag() {
        window.dataLayer.push(arguments);
    }

    useEffect(() => {

        console.log("initApp")

        window.addEventListener("beforeinstallprompt", (event) => {
            // Prevent the mini-infobar from appearing on mobile.
            event.preventDefault();
            console.log("👍", "beforeinstallprompt", event);
            // Stash the event so it can be triggered later.
            window.deferredPrompt = event;
            // Remove the 'hidden' class from the install button container.
            //setIsReadyForInstall(true);
            console.log("ready to download")
        });
        if (window.location.href.indexOf("app.mential.io") > 0) {
            TagManager.initialize({
                gtmId: 'GTM-W3QX5M6'
            })
            window.posthog = postHog
        }
        window.dataLayer = window.dataLayer || [];

        gtag("consent", "default", {
            ad_storage: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied", // optional
            personalization_storage: "denied", // optional
            security_storage: "denied", // optional
            wait_for_update: 2000 // milliseconds
        });
        // Improve ad click measurement quality (optional)
        gtag('set', 'url_passthrough', true);
        // Further redact your ads data (optional)
        gtag("set", "ads_data_redaction", true);
    }, [])
    // Default consent mode is "denied" for both ads and analytics as well as the optional types, but delay for 2 seconds until the Cookie Solution is loaded


    window.track = new ProductAnalytics().trackEvent;


    return (
        <ThemeProvider theme={appTheme}>
                <Suspense fallback={<LoadingView/>}>
                    <Routes>
                        <Route path="/login" element={<Auth/>}/>
                        <Route path="/logout" element={<LogoutView/>}/>
                        <Route path="/recuperar-contraseña" element={<ForgetPasswordView/>}/>
                        <Route path="/assessment" element={<IntroAssessment/>}/>
                        <Route path="/evaluacion/:resourceName" element={<IntroAssessment/>}/>
                        <Route path="/evaluacion" element={<IntroAssessment/>}/>
                        <Route path="/thank-you" element={<ThankyouAssessment/>}/>
                        <Route path="/gracias" element={<ThankyouAssessment/>}/>
                        <Route path="/bienvenido" element={<ThankyouAssessment/>}/>
                        <Route path="/" element={<IntroView/>}/>
                        <Route path="/account" element={<ErrorBoundary><Account showUpdateBtn={appUpdatePending} updateAction={updateAction}/></ErrorBoundary>}/>
                        <Route path="/admin" element={<AdminView/>}/>
                        <Route path="/admin/:view" element={<AdminView/>}/>
                        <Route path="/account/:view/:id"
                               element={<ErrorBoundary fallback={<NotFoundPage/>}><Account showUpdateBtn={appUpdatePending} updateAction={updateAction}/></ErrorBoundary>}/>
                        <Route path="/area-privada/:view"
                               element={<ErrorBoundary fallback={<NotFoundPage/>}><Account showUpdateBtn={appUpdatePending} updateAction={updateAction}/></ErrorBoundary>}/>
                        <Route path="/area-privada/:view/:id"
                               element={<ErrorBoundary fallback={<NotFoundPage/>}><Account showUpdateBtn={appUpdatePending} updateAction={updateAction}/></ErrorBoundary>}/>
                        <Route path="/area-privada/:view/:id/:subview"
                               element={<ErrorBoundary fallback={<NotFoundPage/>}><Account showUpdateBtn={appUpdatePending} updateAction={updateAction}/></ErrorBoundary>}/>
                        <Route path="/area-privada"
                               element={<ErrorBoundary fallback={<NotFoundPage/>}><Account showUpdateBtn={appUpdatePending} updateAction={updateAction}/></ErrorBoundary>}/>
                        <Route path="/assessment/report/:id" element={<AssessmentReportRecovery/>}/>
                        <Route path="/evaluacion/resultados/:id" element={<AssessmentReportRecovery/>}/>
                        <Route path="/start" element={<ProgramOnboarding/>}/>
                        <Route path="/comenzamos" element={<ProgramOnboarding/>}/>
                        <Route path="/sessions/:sessionId" element={<VideoConference/>}/>
                        <Route path="/registro" element={<SignUpView/>}/>
                        <Route path="/signup" element={<SignUpView/>}/>
                        <Route path="/gestion" element={<PartnerAccountView/>}/>

                        <Route path="*" element={<NotFoundPage/>}/>

                    </Routes>
                </Suspense>
        </ThemeProvider>
    )


}

