import { createSlice } from '@reduxjs/toolkit'

export const programsSlide = createSlice({
    name: 'programs',
    initialState: {
        programs: undefined,
        appointments: undefined,
        tasks: undefined,
        communications: {communityMessages: [],chatMessages: []},
    },
    reducers: {
        setPrograms: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.programs = action.payload
        },
        setAppointments: (state, action) => {
            state.appointments = action.payload
        },
        setTasks: (state, action) => {
            state.tasks = action.payload
        },
        setCommunications: (state, action) => {
            state.communications = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setPrograms,setAppointments,setTasks,setCommunications } = programsSlide.actions

export default programsSlide.reducer
