import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {persistor, store} from "./store/store";
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from "react-redux";
import * as Sentry from "@sentry/react";
import "./services/i18n";
import {PostHogProvider} from 'posthog-js/react'
import {BrowserRouter, useLocation} from "react-router-dom";
import {createRoutesFromChildren, matchRoutes, useNavigationType} from "react-router";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

//verify if window.location contains https after 10 position, redirect
if (window.location.href.indexOf("https://", 10) > 0) {
    console.log(window.location.href)
    window.location.href = window.location.href.substring(window.location.href.indexOf("https://", 10));
}

if (window.location.href.indexOf("http://localhost") === -1) {

    let environment = "uat";
    if (window.location.href.indexOf("app.mential.io") > -1) {
        environment = "production";
        Sentry.init({
            dsn: "https://a2ba880b542b497c8f1c499cff642448@o4504079628435456.ingest.sentry.io/4504129801748480",
            //dsn: "https://92f11113c63cab706f9fcc64f92c508e@o4505840693149696.ingest.sentry.io/4505840694198272",
            environment: environment,
            integrations: [new Sentry.BrowserTracing({
                // See docs for support of different versions of variation of react router
                // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }), new Sentry.Replay()],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        });
    }
}

const withSwRegistration = (WrappedComp) => {
    return () => {
        // holds all the SW registration setup
        const [appUpdatePending, setAppUpdatePending] = useState(false);
        const location = useLocation();
        // updates the state when a new update is pending.
        const onSWUpdate = () => {
            setAppUpdatePending(!appUpdatePending);
        }
        // action for updating the service worker.
        const updateAction = () => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.ready.then((registration) => {
                    if (registration.waiting) {
                        // send the skip message to kick off the service worker install.
                        registration.waiting.postMessage({type: 'SKIP_WAITING'});
                        // add an listener to reload page when the new service worker is ready.
                        registration.waiting.addEventListener('statechange', (event: Event) => {
                            const {state = ''} = event.target || {};
                            if (state === 'activated') {
                                window.location.reload();
                            }
                        });
                    }
                });
            }
        };
        // effect added from router location to check for a new service worker on every
        // page transition (change of route).
        useEffect(() => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.ready.then((registration) => {
                    console.log("service worker ready to update")
                    registration.update();
                });
            }
        }, [location]);

        // registers the service worker based on config setting.
            serviceWorkerRegistration.register({onUpdate: onSWUpdate});

        return (
            <WrappedComp updateAction={updateAction} appUpdatePending={appUpdatePending}/>
        )
    }
};

const AppWithSwRegistration = withSwRegistration(App);


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <PostHogProvider
                apiKey="phc_JlOOhHNdARMbmaH4Tpx4yzGAJ4ehGvuBxuIetHqBCAa"
                options={{
                    api_host: 'https://t.mential.io',
                    autocapture: false,
                    capture_pageview: window.location.href.indexOf("app.mential.io") > 0,
                    disable_session_recording: true
                }}
            ><BrowserRouter>
                <AppWithSwRegistration/>
            </BrowserRouter>
            </PostHogProvider>
        </PersistGate>
    </Provider>
);





