import { createSlice } from '@reduxjs/toolkit'

export const assessmentSlide = createSlice({
    name: 'assessment',
    initialState: {
        userId: undefined,
        assessmentId: undefined,
        resourceName: undefined,
        coupon: undefined,
        selectedProgram: undefined,
    },
    reducers: {
        setUserId: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.userId = action.payload
        },
        setAssessmentId: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.assessmentId = action.payload
        },
        setResourceName: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.resourceName = action.payload
        },
        setCoupon: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.coupon = action.payload
        },
        setSelectedProgram: (state, action) => {
            state.selectedProgram = action.payload
        }

    }

})

// Action creators are generated for each case reducer function
export const { setUserId,setAssessmentId,setResourceName,setCoupon,setSelectedProgram } = assessmentSlide.actions

export default assessmentSlide.reducer
