import {createTheme} from "@mui/material";
import Button, { buttonClasses } from "@mui/material/Button";
import {blue, blueGrey, green, grey, lightBlue, orange} from "@mui/material/colors";

export const appTheme = createTheme({
    typography:{
        fontFamily: "Inter",
    },
    palette: {
        primary: {
            main: '#c7c5fa'
        },
        secondary: {
            main: '#D1D5DB',
            secondary: '#EDF1FA'

        },
        white: {
          main: "#FFFFFF"
        },
        third: { //blumine used
            main: '#0A243E',
            //primrose pure
            secondary: '#3248CA'
        },
        four:{
            main: '#3248CA'
        },

        five: {
            main: '#485E75'
        },
        six: {
            main: '#FF5F24',
            light: '#FFA775'
        },
        green: {
            main: '#5FAD56'
        },
        yellow:
            {main: '#FEC619'},
        orange:
            {main: '#FF5F24'},
        purple:
            {main: '#4D5CB5'}
    },
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    [`&.Mui-disabled`]: {
                        backgroundColor:'#b2bcc5 !important'
                    }

                }
            }
        }
    }
});
