import {initializeApp} from "firebase/app";
import AppConfig from "./appConfig";
import {doc, getFirestore, setDoc} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {getMessaging, getToken} from "firebase/messaging";


const appConfig = new AppConfig();
const app = initializeApp(appConfig.dbCred)
const db = getFirestore(app);
const auth = getAuth(app);

let messaging

try {
    messaging = getMessaging(app);
} catch (e) {
    console.warn('Error getting messaging:', e);
}


const persistToken = (uid, token) => {
    // Send the token to your server and update the UI if necessary
    //persist in firebase collection device-tokens-pushn
    setDoc(doc(db, 'device-tokens-pushn', uid), {
        token: token,
        lastUpdatedAt: new Date()
    }).then(() => {
        console.log('Token persisted');
    }).catch((error) => {
        console.error('Error writing document: ', error);
    });
}

const getTokenAndPersist = (userId) => {
    return new Promise((resolve, reject) => {
        getToken(messaging, {vapidKey: appConfig.cloudMessagingVapidKey}).then((currentToken) => {
            if (currentToken) {
                persistToken(userId, currentToken);
                resolve(currentToken);
            } else {
                console.log('No registration token available. Request permission to generate one.');
                reject('No registration token available. Request permission to generate one.');
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            reject(err);
        })
    });
}


const setupMessaging = () => {

    setTimeout(() => {
        if (auth.currentUser) {
            getTokenAndPersist(auth.currentUser.uid).then((token) => {
            }).catch((err) => {
                console.error('Error getting token:', err);
            });
        }
    }, 5000);


}
/*

if (("Notification" in window)) {
    // Check if the browser supports notifications
    console.log(Notification.permission)
    if (Notification.permission === "granted") {
        // Check whether notification permissions have already been granted;
        // if so, create a notification
        setupMessaging();
        // …
    } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                setupMessaging();
            }
        });
    }
} else {
    console.log('This browser does not support notifications.');
}

 */


export {app, db, auth, messaging, setupMessaging, getTokenAndPersist};
