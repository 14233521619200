import {configureStore,combineReducers} from "@reduxjs/toolkit";
import assessmentReducer from "./assessmentSlide"
import authReducer from "./authSlide"
import programReducer from "./programSlide"
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { persistReducer, persistStore} from 'redux-persist';

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = {
    assessment: assessmentReducer,
    auth: authReducer,
    programs: programReducer
};
const persistedReducer = persistReducer(persistConfig, combineReducers(rootReducer))

export const store =  configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
})

export const persistor = persistStore(store)
